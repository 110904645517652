import * as React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

function Copyright() {
  return (
    <React.Fragment>
        <Grid  container justifyContent= "flex-end" >
          <Grid item sx={3}>
              <Typography
              color="#2b3d55"
              align="center"
              variant="h6"
              sx={{ mb: 1, mt: 5 }}
            >
            </Typography>
          </Grid>
          <Grid item sx={9} >
            <Typography 
                variant="caption text"   sx={{ mt: 10 }}>  
                      {'© '}
                <Link color="inherit" href="https://chimerising.com/">
                  Chime Rising
                </Link>{' '}
                {new Date().getFullYear()}      
            </Typography>
          </Grid>
          </Grid>

    </React.Fragment>
  );
}


export default function Footer() {
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex',  bgcolor: '#6f8eb6', pt:3, pb:3, pl:5, spacing:2 }}
    >
        <Grid
            item
            direction="column"
            justifyContent="flex-end"
            spacing={2}
        >
                <Copyright />
        </Grid>

        
    </Typography>
  );
}