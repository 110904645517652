import './App.css';
import * as React from 'react';
import Splash from './Splash';
import TopBilling from './TopBilling';
import Outcomes from './Outcomes';

function Home() {
  return (
    <div className="App">      
      <React.Fragment>

        <Splash />
        <TopBilling />
        <Outcomes />

      </React.Fragment>
      
    </div>
  );
}

export default Home;