import * as React from 'react';
import Typography from '@mui/material/Typography';
import SplashLayout from './SplashLayout';
import splashImage from './images/coot.jpg'
//import Typography from './components/Typography';
// import ProductHeroLayout from './ProductHeroLayout';


  

export default function Splash() {
  return (
    <SplashLayout
      sxBackground={{
        backgroundImage: `url(${splashImage})`,
        backgroundColor: '##6f8eb6', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none'}}
        src={splashImage}
        alt="increase priority"
      />
      <Typography 
        color="#f6eff1" 
        align="center" 
        variant="h2" marked="center">
        Invent your Future
      </Typography>
      <Typography
        color="#522e3a"
        align="center"
        variant="h3"
        sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
      >
        We offer Coaching for Outcomes
      </Typography>

      <Typography variant="h5" 
          color="#522e3a" sx={{ mt: 2 }}>
       {'Create clarity about your most important outcomes '}
       
       </Typography>
       <Typography variant="h5" color="#522e3a" sx={{ mt: 2 }}>
       {'and momentum towards achieving them.'}
      </Typography>
    </SplashLayout>
  );
}