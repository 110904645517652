import './App.css';
import * as React from 'react';
import AppBar from './AppBar'; 
import Footer from './Footer'   

function App() {
  return (
    <div className="App">      
      <React.Fragment>
        <AppBar />
        <Footer />
      </React.Fragment>
      
    </div>
  );
}

export default App;