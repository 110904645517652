import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MuiTypography from '@mui/material/Typography';
//import Typography from './components/Typography';

import IndivImg from './images/risingstar.png';
import TeamImg from './images/team.png';
import OrgImg from './images/big-bang.png';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function TopBilling() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#89a2c3' }}
    >
      <Container sx={{ mt: 12, mb: 8, display: 'flex', position: 'relative' }}>

        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={IndivImg}
                alt="rising star"
                sx={{ height: 55 }}
              />
              <MuiTypography variant="h6" sx={{ my: 5 , color: "white"}}>
                Individual Coaching
              </MuiTypography>
              <MuiTypography variant="h7">
                {
                  'Refine your brand of excellence, your brand leadership - whatever your role. '
                }

                {
                  'Invest in yourself and enjoy the you that you become.'
                }
              </MuiTypography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={TeamImg}
                alt="team together"
                sx={{ height: 55 }}
              />
              <MuiTypography variant="h6" sx={{ my: 5, color :"white" }}>
                Team Coaching
              </MuiTypography>
              <MuiTypography variant="h7">
                {
                  'Be more than a collection of individuals. '
                }

                {'Build strength through collaboration.'}
              </MuiTypography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={OrgImg}
                alt="align"
                sx={{ height: 55 }}
              />
              <MuiTypography variant="h6" sx={{ my: 5, color:"white" }}>
                Organizations
              </MuiTypography>
              <MuiTypography variant="h7">
                {'Align multiple teams to achieve value results for customers. '}
                {'Build a culture that enables teams and individuals to shine.'}
              </MuiTypography>
            </Box>
          </Grid>
          <Grid item container justifyContent= "flex-end" >
            <MuiTypography variant="caption text" fontSize='11px' color='#2b3d55'>
                {
                  '~Icons made by Amoghdesign, Monkik, and Made Premium from www.flaticon.com'
                }
            </MuiTypography>
          </Grid>
        </Grid>
        
      </Container>
    </Box>
  );
}

export default TopBilling;