import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import OutcomeLayout from './OutcomeLayout';
import splashImage from './images/SeekPngbluetie.png';

export default function About() {
  return (
    <OutcomeLayout
      sxBackground={{
        backgroundImage: `url(${splashImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={splashImage}
        alt="increase priority"
      />
      <Typography 
          color="#854B5F" 
          align="center" 
          sx={{ mb: 1, mt: 6}}
          variant="h7" marked="center">
"
Kathy was supportive as a coaching colleague and took the initiative to investigate data and feedback teams shared about our product-led agile transformation in surveys.  It was really exciting for me to see a coach take interest in using this data empathetically to increase our ability to meet the needs of individuals and teams to better articulate and deliver towards product outcomes. 
"
Carol Rose
Product Coach 
      </Typography>
      <Typography 
          color="#854B5F" 
          align="center" 
          sx={{ mb: 1}}
          variant="h7" marked="center">
        I am thinking something here.
      </Typography>
      <Typography 
          color="#854B5F" 
          align="center" 
          
          variant="h7" marked="center">
       Some more text
             </Typography>
      <Typography
        color="#854B5F"
        align="center"
        variant="h5"
        sx={{ mb: 1, mt: 10 }}
      >
        Rise to your highest potential with coaching customized to your needs.
      </Typography>
      <Typography
        color="#2b3d55"
        align="center"
        variant="h6"
        sx={{ mb: 1, mt: 5 }}
      >
        Contact us today to set up coaching. <p></p>Email us at coaching@ChimeRising.com or call 720-696-0442.
      </Typography>
      <Grid item container justifyContent= "flex-end" >
        <Typography 
            variant="caption text"   sx={{ mt: 10 , fontSize:'11px'}}>        
          <a href="https://www.seekpng.com/ipng/u2q8w7a9e6w7e6e6_download-abstract-free-png-transparent-image-and-clipart/" > Abstract transparent image from @seekpng.com</a>
        </Typography>
       </Grid>
    </OutcomeLayout>
  );
}