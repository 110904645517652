import * as React from 'react';
import About from './About';
import Home from './Home';
import Box from '@mui/material/Box';
//import Link from '@mui/material/Link';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from './components/Toolbar';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

const rightLink = {
  fontSize: 16,
  color: 'common.white',
  ml: 3,
};

function AppBar() {
  return (
    <div>
      <Router >
       <MuiAppBar elevation={0} position="fixed" >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ flex: 1 }} />
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            to="/"
            sx={{ fontSize: 24 }}
          >
            {'Chime Rising'}
          </Link>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Link
              color="inherit"
              variant="h6"
              underline="none"
              to="/About"
              sx={rightLink}
            >
              {'About'}
            </Link>
            
          </Box>
        </Toolbar>
      </MuiAppBar>
      <Toolbar />

        <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/about' element={<About/>} />
          
        </Routes>
      </Router>
    </div>
  );
}

export default AppBar;